<template>
  <v-container class="bannerFull" fluid>
    <v-row class="headerText" v-if="geospatials.length !== 0 || loaded1==false">
      <v-col align-self="center">
        <v-progress-circular
          :width="3"
          color="white"
          indeterminate
          v-if="!loaded1"
        ></v-progress-circular>
        <h1 class="white--text ml-4" v-if="geospatials.length !== 0">
          Hasil Pencarian Geospatial "{{ searchQuery }}"
        </h1>
        <h1 class="white--text ml-4" v-if="geospatials.length == 0">
          Silahkan Memasukkan Pencarian
        </h1>
      </v-col>
    </v-row>
    <v-row class="bgBlue" v-if="geospatials.length !== 0">
      <v-sheet
        class="mx-auto"
        v-if="geospatials.length !== 0"
        elevation="8"
        width="1200px"
        max-width="90%"
      >
        <v-slide-group
          v-model="geospatials"
          class="pa-4"
          active-class="success"
          show-arrows
        >
          <v-slide-item
            v-for="(objects, index) in geospatials"
            v-bind:key="index"
          >
            <v-card class="mx-auto mt-5 ml-5 mb-3" max-width="220" width="220">
              <v-img
                :src="objects.thumbnail_url"
                height="200px"
                gradient="to bottom, rgba(255,255,255,.33), #0b789c"
              ></v-img>

              <v-card-title class="col-11 text-truncate">
                {{ objects.title }}
              </v-card-title>

              <v-card-subtitle> {{ objects.owner_name }} </v-card-subtitle>

              <v-card-actions>
                <v-btn
                  target="_blank"
                  :href="
                    'http://geoportal.slemankab.go.id' + objects.detail_url
                  "
                  color="primary lighten-2"
                  text
                >
                  Pelajari
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show = !show">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text>
                    {{ objects.abstract }}
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-row>
    <v-row v-if="data_list.length !== 0" class="headerText">
      <v-col align-self="center">
        <v-progress-circular
          :width="3"
          color="white"
          indeterminate
          v-if="!loaded2"
        ></v-progress-circular>
        <h1 class="white--text ml-4">
          Hasil Pencarian Dataset "{{ searchQuery }}"
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="data_list.length !== 0" class="bgBlue">
      <v-sheet
        class="mx-auto"
        v-if="data_list.length !== 0"
        elevation="8"
        max-width="90%"
        width="1200px"
      >
        <v-slide-group
          v-model="data_list"
          class="pa-4"
          active-class="success"
          show-arrows
        >
          <v-slide-item
            v-for="(objects, index) in data_list"
            v-bind:key="index"
          >
            <v-card class="mx-auto mt-5 ml-5 mb-3" max-width="250" width="250">
              <v-img
                height="200px"
                gradient="to bottom, rgba(255,255,255,.33), #0b789c"
              ></v-img>

              <v-card-title class="col-11 text-truncate">
                {{ objects.title }}
              </v-card-title>

              <v-card-subtitle>
                {{ objects.organization.title }}
              </v-card-subtitle>

              <v-card-actions>
                <v-btn
                  target="_blank"
                  :href="
                    'http://data.slemankab.go.id/data/dataset/' + objects.name
                  "
                  color="primary lighten-2"
                  text
                >
                  Pelajari
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show = !show">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text>
                    {{ objects.resources[0].description }}
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "HelloWorld",
  props: ["searchQuery"],
  data: () => ({
    loaded1: false,
    loaded2: false,
    geospatials: [],
    organization: [],
    group_list: [],
    query: "",
    data_list: [],
    show: false,
    labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
    time: 0,
    question: "",
    answer: "I cannot give you an answer until you search something!",
    forecast: [
      {
        day: "Tuesday",
        icon: "mdi-white-balance-sunny",
        temp: "24\xB0/12\xB0",
      },
      {
        day: "Wednesday",
        icon: "mdi-white-balance-sunny",
        temp: "22\xB0/14\xB0",
      },
      { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" },
    ],
  }),
  computed: {
    myStyles() {
      return { height: "99px" };
    },
  },
  watch: {
    // whenever question changes, this function will run
    $route() {
      this.getGeo();
    },
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.

    const id = to.params.searchQuery;
    console.log(this.searchQuery);
    this.loaded1 = false;
    this.loaded2 = false;
    axios
      .get(
        "http://geoportal.slemankab.go.id/api/base/?limit=20&offset=0&title__icontains=" +
          id
      )
      .then((response) => {
        this.geospatials = response.data.objects;
        console.log(this.geospatials);
        this.loaded1 = true;
      });
    axios
      .get(
        "http://data.slemankab.go.id/data/api/3/action/package_search?q=" + id
      )
      .then((response) => {
        this.data_list = response.data.result.results;
        this.loaded2 = true;
      });
    next();
  },
  mounted() {
    this.loaded1 = false;
    this.loaded2 = false;
    axios
      .get(
        "http://geoportal.slemankab.go.id/api/base/?limit=20&offset=0&title__icontains=" +
          this.searchQuery
      )
      .then((response) => {
        this.geospatials = response.data.objects;
        console.log(this.geospatials);
        this.loaded1 = true;
      });
    axios
      .get(
        "http://data.slemankab.go.id/data/api/3/action/package_search?q=" +
          this.searchQuery
      )
      .then((response) => {
        this.data_list = response.data.result.results;
        this.loaded2 = true;
      });
  },
  methods: {
    getGeo: function () {
      this.loaded1 = false;
      axios
        .get(
          "http://geoportal.slemankab.go.id/api/base/?limit=20&offset=0&title__icontains=" +
            this.searchQuery
        )
        .then((response) => {
          this.geospatials = response.data.objects;
          console.log(this.geospatials);
          this.loaded1 = true;
        });
    },
    getData: function () {
      this.loaded2 = false;
      axios
        .get(
          "http://data.slemankab.go.id/data/api/3/action/package_search?q=" +
            this.searchQuery
        )
        .then((response) => {
          this.data_list = response.data.result.results;
          this.loaded2 = true;
        });
    },
  },
};
</script>

<style lang="scss">
.boldText {
  font-weight: 900;
}
.bannerLeft {
  margin-top: 50px;
  margin-left: 70px;
  margin-right: 50px;
}
.contentLeft {
  background: white;
  margin-top: 0px;
  margin-left: 100px;
  margin-right: 40px;
}
.contentLeftAfter {
  background: white;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 40px;
  position: relative;
}
.contentRight {
  background: white;
  margin-top: 100px;
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
}
.bannerRight {
  margin-top: 60px;
  margin-right: 50px;
  margin-left: 40px;
}
.heightFull {
  height: 500px;
}
@media only screen and (max-width: 1064px) {
  .heightFull {
    height: 750px;
  }
  .bannerLeft {
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .contentLeft {
    background: white;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .contentLeftAfter {
    background: white;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
  }
  .headerText {
    background: linear-gradient(110deg, #0b789c 90%, #ffffff 40%) !important;
    height: 100px;
    font-size: 15px;
  }
  .bgBlue {
    // background-image: linear-gradient(90deg,#0b789c, #86cbe6);
    background: linear-gradient(110deg, #0b789c 20%, #ffffff 20%);
    height: 650px !important;
    margin-right: -11px !important;
  }
}
.bgBlue {
  // background-image: linear-gradient(90deg,#0b789c, #86cbe6);
  background: linear-gradient(110deg, #0b789c 20%, #ffffff 20%);
  height: 570px;
  margin-right: 0px;
}
.bgWhite {
  // background-image: linear-gradient(90deg,#0b789c, #86cbe6);
  // background: linear-gradient(110deg, #0b789c 40%, #ffffff 20%);
  background: #0b789c;
  margin-right: 0px;
}
.headerText {
  background: linear-gradient(110deg, #0b789c 60%, #ffffff 40%);
  height: 150px;
}
.bgRed {
  background-image: linear-gradient(#b6dceb, #ffffff);
  height: 500px;
}
.bawangKothong {
  margin-right: -10%;
  margin-top: 20px;
  margin-left: 10px;
}
.statistikData {
  background: linear-gradient(110deg, #ffffff 80%, #0b789c 20%);
  height: 100px;
}
.statistikDataContent {
  background: linear-gradient(110deg, #ffffff 70%, #0b789c 20%);
  height: 500px;
}
</style>
