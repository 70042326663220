<template>
  <v-container class="bannerFull" fluid>
    <v-row class="heightFull">
      <v-col class="align-center bannerLeft">
        <h1 class="align-center primary--text font-weight-black">
          Sleman Satu Data
        </h1>
        <p class="text-justify">
          Satu Data Kabupaten Sleman adalah kebijakan tata kelola data
          Pemerintah Kabupaten Sleman untuk menghasilkan data yang akurat,
          mutakhir, terpadu, dan dapat dipertanggungjawabkan, serta mudah
          diakses dan dibagipakaikan antar Instansi Pusat, instansi pemerintah
          provinsi dan Instansi Pemerintah Kabupaten melalui pemenuhan standar
          data, metadata, interoperabilitas data, dan menggunakan kode referensi
          dan data induk.
        </p>
        <v-btn href="https://data.slemankab.go.id/data/dataset" color="primary" class="ml-5 mr-4" width="120px" rounded>
          Data
        </v-btn>
        <v-btn  href="https://geoportal.slemankab.go.id/" color="primary" rounded outlined> GeoPortal </v-btn>
      </v-col>
      <v-col class="hidden-sm-and-down">
        <v-img
          :src="require('../assets/BannerDepan.png')"
          contain
          class="bannerRight "
          height="250"
        />
      </v-col>
    </v-row>
    <v-row class="headerText">
      <v-col align-self="center">
        <h1 class="white--text ml-4">GeoPortal Highlights</h1>
      </v-col>
    </v-row>
    <v-row class="bgBlue">
      <v-col
        v-for="(objects, index) in geospatials"
        v-bind:key="index"
        lg="3"
        md="3"
        sm="12"
        xs="12"
        :class="index == 0 ? 'contentLeft' : 'contentLeftAfter'"
      >
        <v-item>
          <v-card class="mx-auto" max-width="400" >
            <v-img
              :src="objects.thumbnail_url"
              height="200px"
              gradient="to bottom, rgba(255,255,255,.33), #003d51"
            ></v-img>

            <v-card-title class="col-11 text-truncate">
              {{ objects.title }}
            </v-card-title>

            <v-card-subtitle> {{ objects.owner_name }} </v-card-subtitle>

            <v-card-actions>
              <v-btn target="_blank" :href="'https://geoportal.slemankab.go.id'+objects.detail_url" color="primary lighten-2" text> Pelajari </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="show = !show">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>

                <v-card-text>
                  {{ objects.abstract }}
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        align-self="center"
        class="statistikData"
        lg="12"
        md="12"
        sm="12"
        xs="12"
      >
        <h1 class="primary--text text-center">Sleman Satu Data Statistics</h1>
      </v-col>
    </v-row>
    <v-row class="bgWhite">
      <v-col lg="4" md="3" sm="12" xs="12">
        <v-item>
          <v-card class="mx-auto" max-width="400">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline"> SKPD </v-list-item-title>
                <v-list-item-subtitle
                  >Total SKPD yang sudah memasukkan data</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row align="center">
                <v-col class="display-3" cols="6">
                  {{ organization.length }}
                </v-col>
                <v-col cols="6">
                  <v-img
                    src="../assets/teamwork.svg"
                    alt="Team Work"
                    width="100"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>

            <v-list class="transparent">
              <v-list-item v-for="item in organization.slice(0, 5)" :key="item.id" >
                <a target="_blank" :href="'https://data.slemankab.go.id/data/organization/'+item.name" class="elypsis">
                <v-list-item-title>{{ item.display_name }}</v-list-item-title>
                </a>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn href="https://data.slemankab.go.id/data/organization" text>Lebih Lengkap </v-btn>
            </v-card-actions>
          </v-card>
        </v-item>
      </v-col>
      <v-col lg="4" md="3" sm="12" xs="12">
        <v-item>
          <v-card class="mx-auto" max-width="400">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Bidang Data
                </v-list-item-title>
                <v-list-item-subtitle>Kategori Data</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row align="center">
                <v-col class="display-3" cols="6">
                  {{ group_list.length }}
                </v-col>
                <v-col cols="6">
                  <v-img
                    src="../assets/dataset.png"
                    alt="Team Work"
                    width="80"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>

            <v-list class="transparent">
              <v-list-item v-for="item in group_list.slice(0, 5)" :key="item.id">
                <a target="_blank" :href="'https://data.slemankab.go.id/data/group/'+item.name" class="elypsis">
                <v-list-item-title>{{ item.display_name }}</v-list-item-title>
                </a>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn href="https://data.slemankab.go.id/data/group" text>Lebih Lengkap </v-btn>
            </v-card-actions>
          </v-card>
        </v-item>
      </v-col>
      <v-col lg="4" md="3" sm="12" xs="12">
        <v-item>
          <v-card class="mx-auto" max-width="400">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Dataset
                </v-list-item-title>
                <v-list-item-subtitle
                  >Data yang sudah terupload</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row align="center">
                <v-col class="display-3" cols="6">
                  {{ number_data }}
                </v-col>
                <v-col cols="6">
                  <v-img
                    src="../assets/kategori.png"
                    alt="Team Work"
                    width="80"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>

            <v-list class="transparent">
              <v-list-item v-for="item in data_list.slice(0, 5)" :key="item.id">
                <a target="_blank" :href="item.resources[0].url" class="elypsis">
                <v-list-item-title >{{ item.title }}</v-list-item-title>
                </a>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn href="https://data.slemankab.go.id/data/dataset" text>Lebih Lengkap </v-btn>
            </v-card-actions>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script src="https://cdn.jsdelivr.net/npm/lodash@4.13.1/lodash.min.js"></script>
<script>
import axios from "axios";
const api_satudata = process.env.VUE_APP_FULLURLAPI;
const base_geo = process.env.VUE_APP_URLGEO;
export default {
  name: "HelloWorld",
  data: () => ({
    loaded: false,
    geospatials: [],
    organization: [],
    group_list: [],
    data_list: [],
    number_data: 0,
    show: false,
    labels: ["SU", "MO", "TU", "WED", "TH", "FR", "SA"],
    time: 0,
    forecast: [
      {
        day: "Tuesday",
        icon: "mdi-white-balance-sunny",
        temp: "24\xB0/12\xB0",
      },
      {
        day: "Wednesday",
        icon: "mdi-white-balance-sunny",
        temp: "22\xB0/14\xB0",
      },
      { day: "Thursday", icon: "mdi-cloud", temp: "25\xB0/15\xB0" },
    ],
  }),
  computed: {
    myStyles() {
      return { height: "99px" };
    },
  },
  mounted() {
    axios
      .get(base_geo+"api/featured/")
      .then((response) => {
        this.geospatials = response.data.objects;
        console.log(this.geospatials);
      });
    axios
      .get(
        api_satudata+"/organization_list?all_fields=true&order_by=packages"
      )
      .then((response) => {
        this.organization = response.data.result;
        this.loaded = true;
      });
    axios
      .get(
        api_satudata+"/group_list?all_fields=true"
      )
      .then((response) => {
        this.group_list = response.data.result;
        this.loaded = true;
      });
    axios
      .get(
        api_satudata+"/current_package_list_with_resources?all_fields=true"
      )
      .then((response) => {
        this.data_list = response.data.result;
        this.loaded = true;
      });
    axios
      .get(
        api_satudata+"/package_list"
      )
      .then((response) => {
        this.number_data = response.data.result.length;
        this.loaded = true;
      });
  },
};
</script>

<style scoped lang="scss">
.boldText {
  font-weight: 900;
}
.bannerLeft {
  margin-top: 50px;
  margin-left: 70px;
  margin-right: 50px;
}
.contentLeft {
  background: white;
  margin-top: 0px;
  margin-left: 100px;
  margin-right: 40px;
}
.contentLeftAfter {
  background: white;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 40px;
  position: relative;
}
.contentRight {
  background: white;
  margin-top: 100px;
  margin-right: 0px;
  margin-left: 0px;
  display: flex;
}
.bannerRight {
  margin-top: 60px;
  margin-right: 50px;
  margin-left: 40px;
}
.bannerFull {
  background-image: linear-gradient(#ffffff, #b6dceb);
  height: 100%;
}
.elypsis{
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.heightFull {
  height: 500px;
}
  .cardWidth {
    width:350px;
  }
@media only screen and (max-width: 1064px) {
  .heightFull {
    height: 450px;
  }
  .cardWidth {
    width:150px;
  }
  .contentLeft {
    background: white;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .headerText {
    background: linear-gradient(110deg, #0b789c 80%, #ffffff 40%) !important;
    height: 100px;
    font-size: 10px;
  }
  .contentLeftAfter {
    background: white;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    position: relative;
  }
  .bgBlue {
    // background-image: linear-gradient(90deg,#0b789c, #86cbe6);
    background: linear-gradient(110deg, #0b789c 20%, #ffffff 20%);
    margin-right: -11px;
    height: 1550px !important;
  }

  .statistikData {
  background: linear-gradient(110deg, #ffffff 100%, #003d51 0%) !important;
  height: 100px ;
    font-size: 12px;
}
}
.bgBlue {
  // background-image: linear-gradient(90deg,#0b789c, #86cbe6);
  background: linear-gradient(110deg, #0b789c 20%, #ffffff 20%);
  height: 570px;
  margin-right: -11px;
}
.bgWhite {
  // background-image: linear-gradient(90deg,#0b789c, #86cbe6);
  // background: linear-gradient(110deg, #0b789c 40%, #ffffff 20%);
  background:  #0b789c ;
  margin-right: -11px;
}
.headerText {
  background: linear-gradient(110deg, #0b789c 40%, #ffffff 40%);
  height: 100px;
}
.bgRed {
  background-image: linear-gradient(#b6dceb, #ffffff);
  height: 500px;
}
.bawangKothong {
  margin-right: -10%;
  margin-top: 20px;
  margin-left: 10px;
}
.statistikData {
  background: linear-gradient(110deg, #ffffff 80%, #0b789c 20%);
  height: 100px;
}
.statistikDataContent {
  background: linear-gradient(110deg, #ffffff 70%, #0b789c 20%);
  height: 500px;
}
</style>
