<template>
  <v-app>
    <v-app-bar app hide-on-scroll color="white" dense flat>
      <a class="titleNav" href="/">
        <div class="d-flex align-center" to="/">
          <v-img
            alt="Satu Data Logo"
            class="shrink mr-2"
            contain
            src="./assets/Sleman.png"
            transition="scale-transition"
            width="30"
          />
          <span class="mr-2 d-none d-md-block">Sleman Satu Data</span>
        </div>
      </a>

      <v-spacer></v-spacer>
      <v-text-field
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        v-model="searchQuery"
        v-on:change="onSearch"
        placeholder="Cari Apapun Disini"
      ></v-text-field>
      <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i" :href="item.link">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        href="http://data.slemankab.go.id/data/"
        text
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <span class="mr-2">Data Statistik</span>
      </v-btn>
      <v-btn
        href="http://geoportal.slemankab.go.id/"
        text
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <span class="mr-2">Data Spasial</span>
      </v-btn>
      <v-btn href="/#/unduhan" text v-if="$vuetify.breakpoint.mdAndUp">
        <span class="mr-2">Unduhan</span>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" text v-on="on">
            <v-icon left> mdi-chevron-down </v-icon> Survei
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items2"
            :key="index"
            :href="item.link"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        href="http://ppid.slemankab.go.id/"
        text
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <span class="mr-2">Permohonan Data</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} ©
        <strong>Dinas Kominfo Sleman </strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
// import Search from './components/Search';

export default {
  name: "App",

  components: {
    // HelloWorld,
  },

  data: () => ({
    searchQuery: "",
    items: [
      { title: "Data Statistik", link: "http://data.slemankab.go.id/data/" },
      { title: "Data Spasial", link: "http://geoportal.slemankab.go.id/" },
      { title: "Unduhan", link: "/#/unduhan" },
      { title: "Permohonan Data", link: "http://ppid.slemankab.go.id/" },
    ],
    items2: [
      { title: "Daftar Survei", link: "/#/daftarsurvei" },
      { title: "Survei Online", link: "https://bit.ly/43gHfx2" },
      { title: "Lapor Hasil Survei", link: "https://s.id/HasilSurveiSleman" },
      { title: "Publikasi Hasil Survei", link: "/#/publikasihasilsurvei" },
    ],
  }),
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    onSearch() {
      if (this.query !== "")
        this.$router.push({ path: `/search/${this.searchQuery}` });
    },
  },
  mounted() {
    var owa_baseUrl = "https://analytics.slemankab.go.id/";
    var owa_cmds = owa_cmds || [];
    owa_cmds.push(["setSiteId", "9686e2e7a0b03702aebbc8b222c7642f"]);
    owa_cmds.push(["trackPageView"]);
    owa_cmds.push(["trackClicks"]);

    (function () {
      var _owa = document.createElement("script");
      _owa.type = "text/javascript";
      _owa.async = true;
      owa_baseUrl =
        "https:" == document.location.protocol
          ? window.owa_baseSecUrl || owa_baseUrl.replace(/http:/, "https:")
          : owa_baseUrl;
      _owa.src = owa_baseUrl + "modules/base/js/owa.tracker-combined-min.js";
      var _owa_s = document.getElementsByTagName("script")[0];
      _owa_s.parentNode.insertBefore(_owa, _owa_s);
    })();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,200;0,400;0,500;0,600;0,700;0,900;1,100;1,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Comfortaa&display=swap");

$body-font-family: "Barlow";
$title-font: "Comfortaa";

.v-btn {
  text-transform: none !important;
}
.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    font-family: $title-font, sans-serif !important;
  }
}
.v-sheet.v-app-bar {
  box-shadow: none;
}

.titleNav {
  text-decoration: none;
}
</style>