<template>
<v-container class="bannerFull" fluid>
    <v-row class="headerText">
      <v-col align-self="center">
        <h1 class="white--text ml-4" >
          Unduh tentang Satu Data
        </h1>
      </v-col>
    </v-row>
    <v-row>
        <v-card
    class="mx-auto mt-5"
    max-width="90%"
    width="900px"
  >
    <v-list>
      <v-list-item-group >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          target="_blank" :href="item.link"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
          <v-btn target="_blank" :href="item.link" icon>
        <v-icon>mdi-cloud-download</v-icon>
      </v-btn>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
    </v-row>
</v-container>

</template>

<script>
export default {
  data: () => ({
      publicPath: process.env.BASE_URL,
    items: [
      {
        title: "Peraturan Bupati Satu Data",
        icon: "mdi-file-download",
        link: "https://jdih.slemankab.go.id/download/?id=2713",
      },
      {
        title: "Peraturan Bupati Geo Spatial",
        icon: "mdi-file-download",
        link: "https://jdih.slemankab.go.id/download/?id=2171",
      },
      {
        title: "Satu Data Metadata",
        icon: "mdi-file-download",
        link: `/files/metadata.pdf`,
      },
      {
        title: "User Manual Satu Data",
        icon: "mdi-file-download",
        link: `/files/usermanual.pdf`,
      },
    ],
  }),
};
</script>

<style>
</style>