<template>
  <v-container class="bannerFull" fluid>
    <v-row class="headerText">
      <v-col align-self="center">
        <h1 class="white--text ml-4">Daftar Survei</h1>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row>
        <v-col align-self="center">
          <iframe
            width="100%"
            height="600px"
            src="https://docs.google.com/forms/d/e/1FAIpQLSctYWgjwk3SI7sRhBaxUFuE1uzaTc_RS1seUcsAYSyZajxHJg/viewform"
            title="description"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>